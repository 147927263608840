import { DialogActions, DialogContent } from '@mui/material'
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { SyntheticEvent, useState } from 'react'
import { Mutation_Root } from 'src/@types/graphql'
import { queryAllFactsheets } from 'src/screens/factsheets/factsheet/factsheetQueries'
import { updateProjectBaseFactsheetId } from 'src/screens/pf-kap/project/details/basic-information/basicInformationQueries'
import { RelatedFactsheetsGrid } from 'src/screens/shared/factsheet-relation/RelatedFactsheetsGrid'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { USER_STATUS } from 'src/shared/constants/constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { useClient, useQuery } from 'urql'

interface Props {
  onCancel: () => void
  onSuccess: () => void
  projectBaseId: number | null
}

export const PfKapLinkRelatedFactsheetModalDialog = ({ onSuccess, onCancel, projectBaseId }: Props) => {
  const gridRef = useGridApiRef()
  const locale = useUserLocale()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])
  const urqlClient = useClient()
  const notificationService = useNotificationService()

  const [{ data, fetching }] = useQuery({
    query: queryAllFactsheets,
    variables: {
      locale,
      where: { status: { _eq: USER_STATUS.ACTIVE } },
    },
  })

  const onSubmit = () => {
    document
      .getElementById('related-factsheet-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const submitHandler = async (event: SyntheticEvent) => {
    event.preventDefault()
    const rows = gridRef.current.getSelectedRows()
    const selectedFactsheet = rows.get(selectionModel[0])

    const { data, error } = await urqlClient
      .mutation<{
        update_project_base: Mutation_Root['update_project_base']
      }>(updateProjectBaseFactsheetId, {
        projectBaseId,
        factsheetId: selectedFactsheet?.id,
      })
      .toPromise()

    if (error || data?.update_project_base?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      onSuccess()
    }
  }

  return (
    <>
      <DialogContent>
        {!fetching && data && (
          <>
            <HelpAndInstructions labelKey="label.help.link.related.factsheet" defaultExpansion />
            <form
              id="related-factsheet-form"
              onSubmit={submitHandler}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <RelatedFactsheetsGrid
                factsheets={data.factsheet}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                gridRef={gridRef}
              />
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <PrimaryButton messageKey={'button.link.factsheet'} disabled={selectionModel.length === 0} onClick={onSubmit} />
      </DialogActions>
    </>
  )
}
