import { DialogActions, DialogContent } from '@mui/material'
import { GridRowSelectionModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { SyntheticEvent, useEffect, useState } from 'react'
import { Factsheet, Mutation_Root, Query_Root } from 'src/@types/graphql'
import { updateKapMeasureFactsheetIdMutation } from 'src/screens/kap/program/details/measures/kapMeasuresQueries'
import { RelatedFactsheetsGrid } from 'src/screens/shared/factsheet-relation/RelatedFactsheetsGrid'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import { MODULE_TYPES } from 'src/shared/constants/constants'
import { HelpAndInstructions } from 'src/shared/presentation/HelpAndInstructions'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { useUserLocale } from 'src/user/UserContext'
import { gql, useClient } from 'urql'

export const availableFactsheetsForKapMeasureQuery = gql`
  query availableFactsheetsForKapMeasure($level: String!, $modules: [String!], $locale: String!) {
    factsheet(
      where: {
        _and: [{ level: { _eq: $level } }, { modules: { _has_keys_any: $modules } }, { status: { _eq: "ACTIVE" } }]
      }
    ) {
      id
      level
      modules
      status
      title(path: $locale)
    }
  }
`

interface Props {
  onCancel: () => void
  onSuccess: () => void
  measureId: number
  modules: MODULE_TYPES[]
  level: string
}

export const KapLinkRelatedFactsheetModalDialog = ({ onSuccess, onCancel, measureId, modules, level }: Props) => {
  const gridRef = useGridApiRef()
  const urqlClient = useClient()
  const locale = useUserLocale()
  const notificationService = useNotificationService()

  const [factsheets, setFactsheets] = useState<Factsheet[]>()
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([])

  useEffect(() => {
    const fetchAvailableRelatableFactsheets = async () => {
      const { data } = await urqlClient
        .query<{
          factsheet: Query_Root['factsheet']
        }>(availableFactsheetsForKapMeasureQuery, {
          level: level,
          modules: modules,
          locale: locale,
        })
        .toPromise()

      if (data) {
        setFactsheets(data.factsheet)
      } else {
        notificationService.operationFailed()
      }
    }

    fetchAvailableRelatableFactsheets()
  }, [level, modules, locale, urqlClient, notificationService])

  const onSubmit = () => {
    document
      .getElementById('related-factsheet-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  const submitHandler = async (event: SyntheticEvent) => {
    event.preventDefault()
    const rows = gridRef.current.getSelectedRows()
    const selectedFactsheet = rows.get(selectionModel[0])

    const { data, error } = await urqlClient
      .mutation<{
        update_kap_measure: Mutation_Root['update_kap_measure']
      }>(updateKapMeasureFactsheetIdMutation, {
        measureId: measureId,
        factsheetId: selectedFactsheet?.id,
      })
      .toPromise()

    if (error || data?.update_kap_measure?.affected_rows !== 1) {
      notificationService.operationFailed()
    } else {
      notificationService.changesSaved()
      onSuccess()
    }
  }

  return (
    <>
      <DialogContent>
        {factsheets && (
          <>
            <HelpAndInstructions labelKey="label.help.kap.measure.related.factsheet" defaultExpansion />
            <form
              id="related-factsheet-form"
              onSubmit={submitHandler}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                }
              }}
            >
              <RelatedFactsheetsGrid
                factsheets={factsheets}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
                gridRef={gridRef}
              />
            </form>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <SecondaryButton messageKey={'button.cancel'} onClick={onCancel} />
        <PrimaryButton messageKey={'button.link.factsheet'} disabled={!selectionModel.length} onClick={onSubmit} />
      </DialogActions>
    </>
  )
}
