import { Box, DialogActions, DialogContent, Stack } from '@mui/material'
import { ReactElement, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { Mutation_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { createKapProgramMutation } from 'src/screens/kap/index/kapProgramGridQueries'
import { PrimaryButton, SecondaryButton } from 'src/shared/button/Buttons'
import {
  CANTONS,
  CANTON_TYPE,
  DOSSIER_STATUS,
  DOSSIER_STATUS_TYPE,
  LIFE_PHASES,
  LIFE_PHASE_TYPES,
  PROJECT,
  PROJECT_TYPE,
  TEXT_LENGTH,
} from 'src/shared/constants/constants'
import { AutoCompleteField } from 'src/shared/form/control/AutoCompleteField'
import { MultiSelectField } from 'src/shared/form/control/MultiSelectField'
import { TextField } from 'src/shared/form/control/TextField'
import { DirtyFormSpy } from 'src/shared/form/dirty/DirtyFormSpy'
import { createDecorators } from 'src/shared/form/utils/decorators'
import { composeValidators, maxChar, required } from 'src/shared/form/validation/validators'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import { Utils } from 'src/shared/utils/Utils'
import { UserContext } from 'src/user/UserContext'
import { useClient } from 'urql'

interface CreateKapProgramModalDialogProps {
  onCancel: () => void
  onSuccess: (programId: number) => void
}

interface FormValues {
  title: string
  shortTitle: string
  status: DOSSIER_STATUS_TYPE
  type: PROJECT_TYPE
  canton: CANTON_TYPE | null
  modules: LIFE_PHASE_TYPES[]
}

const initialValuesFactory = (): FormValues => {
  return {
    title: '',
    shortTitle: '',
    status: DOSSIER_STATUS.CONCEPT,
    type: PROJECT.KAP,
    canton: null,
    modules: [],
  }
}

const decorators = createDecorators()

export const CreateKapProgramModalDialog = ({
  onCancel,
  onSuccess,
}: CreateKapProgramModalDialogProps): ReactElement => {
  const [initialValues, setInitialValues] = useState<FormValues | null>(null)
  const { getMessage } = useMessageSource()
  const [cantonTypeOptions, setCantonTypeOptions] = useState<{ label: string; value: string }[]>([])
  const [moduleTypesOptions, setModuleTypesOptions] = useState<{ label: string; value: string }[]>([])
  const modulesArray = LIFE_PHASES
  const urqlClient = useClient()
  const notificationService = useNotificationService()
  const { user } = useContext(UserContext)
  const globalRoles = user.roles
  const cantonalUserCanton = user.cantonalUserCanton

  useEffect(() => {
    const initializeFormValues = () => {
      const moduleTypesOptions = modulesArray.map((module) => ({
        label: `${getMessage(`label.module.description.${module}`)}`,
        value: module,
      }))

      const gfchUserCantonTypesOptions = Object.values(CANTONS).map((canton) => ({
        label: `${getMessage(`label.canton.${canton}`)} (${canton})`,
        value: canton,
      }))

      const cantonalUserCantonTypesOptions = Object.values(CANTONS)
        .filter((canton) => canton === cantonalUserCanton)
        .map((canton) => ({
          label: `${getMessage(`label.canton.${canton}`)} (${canton})`,
          value: canton,
        }))

      const isCantonalUser = Utils.isCantonalRole(globalRoles)
      const cantonTypesOptions = isCantonalUser ? cantonalUserCantonTypesOptions : gfchUserCantonTypesOptions

      setModuleTypesOptions(moduleTypesOptions)
      setCantonTypeOptions(cantonTypesOptions)
      setInitialValues(initialValuesFactory())
    }

    initializeFormValues()
  }, [getMessage, modulesArray, cantonalUserCanton, globalRoles])

  const handleSubmitLocal = async (values: FormValues): Promise<any> => {
    values = {
      ...values,
      modules: Utils.sortModules(values.modules) as LIFE_PHASE_TYPES[],
    }

    const { data } = await urqlClient
      .mutation<{ insert_dossier: Mutation_Root['insert_dossier'] }, FormValues>(createKapProgramMutation, {
        ...values,
      })
      .toPromise()

    const programId = data?.insert_dossier?.returning?.[0].kap_program?.id

    if (data && programId) {
      setInitialValues(values)
      notificationService.changesSaved()
      onSuccess(programId)
    } else {
      notificationService.operationFailed()
    }
  }

  return (
    <>
      <Box>
        {initialValues && (
          <Form<FormValues>
            initialValues={initialValues}
            onSubmit={handleSubmitLocal}
            decorators={decorators}
            render={({ handleSubmit }) => (
              <form onSubmit={handleSubmit} noValidate>
                <DialogContent>
                  <Stack spacing={2}>
                    <TextField
                      required
                      label={getMessage('label.title')}
                      name="title"
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.M))}
                    />
                    <TextField
                      required
                      label={getMessage('label.short.title')}
                      name="shortTitle"
                      validate={composeValidators(required(), maxChar(TEXT_LENGTH.S))}
                    />
                    <MultiSelectField
                      required
                      options={moduleTypesOptions}
                      name="modules"
                      label={getMessage('label.modules')}
                      validate={required()}
                    />
                    <AutoCompleteField
                      required
                      label={getMessage('label.canton')}
                      name="canton"
                      options={cantonTypeOptions}
                      validate={required()}
                    />
                    <DirtyFormSpy />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <SecondaryButton messageKey="button.close" onClick={onCancel} />
                  <PrimaryButton messageKey="button.submit" type="submit" />
                </DialogActions>
              </form>
            )}
          />
        )}
      </Box>
    </>
  )
}
