import { useEffect, useState } from 'react'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { FilterFieldWrapper } from 'src/screens/shared/common/filter-card/FilterFieldWrapper'
import { ALL_MODULES, MODULE_TYPES } from 'src/shared/constants/constants'
import { FilterCheckboxGroupField, FilterCheckboxModel } from 'src/shared/form/control/FilterCheckboxGroupField'
import { requiredSelection } from 'src/shared/form/validation/validators'

interface Props {
  name: string
  error?: boolean
  formValues: MODULE_TYPES[] | undefined
}

export const ModulesFilter = ({ name, error, formValues }: Props) => {
  const { getMessage } = useMessageSource()
  const modulesArray = ALL_MODULES

  const [modulesData, setModulesData] = useState<FilterCheckboxModel[]>([])

  useEffect(() => {
    const initializeValues = () => {
      const modules = modulesArray.map((module) => ({
        // todo GFS008GFSP-1242 to be revisited
        label: module.startsWith('LIFE_PHASE')
          ? `${getMessage(`label.module.${module}`)}`
          : `${getMessage('label.module')} ${module}`,
        value: module,
        tooltipLabel: `${getMessage(`label.module.description.${module}`)}`,
        checked: true,
      }))

      setModulesData(modules)
    }

    initializeValues()
  }, [getMessage, modulesArray])

  return (
    <FilterFieldWrapper label={getMessage('label.modules')} required error={error}>
      <FilterCheckboxGroupField
        data={modulesData}
        name={name}
        validate={requiredSelection()}
        tooltip
        formValues={formValues}
      />
    </FilterFieldWrapper>
  )
}
