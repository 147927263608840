import {
  DataGridPro,
  getGridStringOperators,
  GridColDef,
  GridFilterInputValueProps,
  GridFilterItem,
  GridPaginationModel,
  GridRowModel,
  GridSortItem,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import combinedQuery from 'graphql-combine-query'
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Query_Root } from 'src/@types/graphql'
import { useMessageSource } from 'src/i18n/useMessageSource'
import { ROUTES } from 'src/routing/routes'
import {
  getApplicationTypesQuery,
  getFundingRoundsByProcess,
} from 'src/screens/administration/round-management/round-management-queries'
import { fetchPfKapRows, FetchRowsPromise } from 'src/screens/pf-kap/index/PfKapGridUtils'
import { fetchPfPgvRows } from 'src/screens/pf-pgv/index/PfPgvGridUtils'
import { fetchGfchResponsibleUsersForProcessQuery } from 'src/screens/shared/project/index/projectGridQueries'
import { ALL_MODULES, PROJECT, PROJECT_STATUSES, PROJECT_TYPE } from 'src/shared/constants/constants'
import { Option } from 'src/shared/form/control'
import { AutoCompleteFilterInput } from 'src/shared/form/grid/filter/AutoCompleteFilterInput'
import { useGridTranslateHook } from 'src/shared/utils/hooks/grid-translate-hook'
import { useDelayedNavigate } from 'src/shared/utils/hooks/navigation-hooks'
import { useNotificationService } from 'src/shared/utils/NotificationService'
import StorageUtils, { STORAGE_KEY } from 'src/shared/utils/StorageUtils'
import styled from 'styled-components/macro'
import { useClient } from 'urql'

const DataGridProStyled = styled(DataGridPro)`
  & .MuiDataGrid-row {
    &:hover {
      cursor: pointer;
    }
  }
`

interface Props {
  process: PROJECT_TYPE
  ProjectToolbar: ReactElement
}

const PAGE_SIZE = 10

export const ProjectCockpitGrid = ({ process, ProjectToolbar }: Props): ReactElement => {
  const { getMessage } = useMessageSource()
  const notificationService = useNotificationService()
  const gridTranslations = useGridTranslateHook()
  const gridRef = useGridApiRef()
  const urqlClient = useClient()
  const navigate = useDelayedNavigate()

  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [rowsCount, setRowsCount] = useState(0)
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: PAGE_SIZE })
  const [applicationTypeOptions, setApplicationTypeOptions] = useState<Option[]>([])
  const [fundingRoundOptions, setFundingRoundOptions] = useState<Option[]>([])
  const [gfchResponsibleOptions, setGfchResponsibleOptions] = useState<Option[]>([])

  const handleRowClick = (params: GridRowModel) => {
    const projectId = params?.id
    if (process === PROJECT.PF_KAP) {
      navigate(ROUTES.PfKapDetailsRoot.nested.BasicInformation.params({ projectId }))
    } else if (process === PROJECT.PF_PGV) {
      navigate(ROUTES.PfPgvDetailsRoot.nested.BasicInformation.params({ projectId }))
    } else {
      throw Error(`Unknown process ${process}`)
    }
  }

  const autoCompleteFilterOperator = (options: Option[], label: string, placeholder: string) =>
    getGridStringOperators()
      .filter((operator) => operator.value === 'contains')
      .map((operator) => ({
        ...operator,
        InputComponent: (gridFilterProps: GridFilterInputValueProps) => (
          <AutoCompleteFilterInput {...gridFilterProps} options={options} label={label} placeholder={placeholder} />
        ),
      }))

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'id',
        flex: 0.5,
        headerName: getMessage('label.project.id'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'equals'),
        renderCell: ({ row }) => `${getMessage(`label.project.id.character.${process.toLowerCase()}`)}${row.id}`,
      },
      {
        field: 'short_title',
        flex: 2,
        headerName: getMessage('label.short.title'),
        filterOperators: getGridStringOperators().filter((x) => x.value === 'contains'),
      },
      {
        field: 'status',
        flex: 0.5,
        headerName: getMessage('label.project.status'),
        filterOperators: autoCompleteFilterOperator(
          PROJECT_STATUSES.map((x) => ({
            label: getMessage(`label.project.status.${x}`),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'funding_round_name',
        flex: 0.8,
        headerName: getMessage('label.funding.round'),
        filterOperators: autoCompleteFilterOperator(
          fundingRoundOptions,
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'application_type',
        flex: 1,
        headerName: getMessage('label.application_type'),
        filterOperators: autoCompleteFilterOperator(
          applicationTypeOptions,
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'gfch_responsible',
        flex: 1.5,
        headerName: getMessage('label.gfch.responsible'),
        filterOperators: autoCompleteFilterOperator(
          gfchResponsibleOptions,
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
      {
        field: 'modules',
        flex: 0.5,
        hide: process === PROJECT.PF_PGV,
        headerName: getMessage('label.modules'),
        filterOperators: autoCompleteFilterOperator(
          ALL_MODULES.map((x) => ({
            label: getMessage(`label.module.${x}`),
            value: x,
          })),
          getMessage('label.grid.value'),
          getMessage('label.grid.filter.value'),
        ),
      },
    ],
    [getMessage, applicationTypeOptions, fundingRoundOptions, gfchResponsibleOptions, process],
  )

  const fetchRowsByProcess = useCallback(
    async (
      rowsPerPage: number,
      items?: GridFilterItem[],
      pageParams?: number,
      sort?: GridSortItem,
    ): Promise<FetchRowsPromise> => {
      if (process === PROJECT.PF_KAP) {
        return fetchPfKapRows(urqlClient)(rowsPerPage, items, pageParams, sort)
      } else if (process === PROJECT.PF_PGV) {
        return fetchPfPgvRows(urqlClient)(rowsPerPage, items, pageParams, sort)
      } else {
        throw Error(`Unknown process: ${process}`)
      }
    },
    [process, urqlClient],
  )

  const fetchRows = useCallback(
    async (rowsPerPage = PAGE_SIZE, items?: GridFilterItem[], pageParams?: number, sort?: GridSortItem) => {
      setLoading(true)
      try {
        const { rows, rowsCount } = await fetchRowsByProcess(rowsPerPage, items, pageParams, sort)

        const rowsAdapted = rows.map((r) => ({
          ...r,
          application_type: getMessage(r.application_type),
          status: getMessage(`label.project.status.${r.status}`),
          modules: r.modules?.map((m) => getMessage(`label.module.${m}`)).join(', '),
        }))
        setRows(rowsAdapted)
        setRowsCount(rowsCount)
      } catch (e) {
        notificationService.operationFailed()
      } finally {
        setLoading(false)
      }
    },
    [notificationService, getMessage, fetchRowsByProcess],
  )

  const onGridChange = useCallback(
    async (reason?: string) => {
      if (gridRef?.current) {
        const sort = gridRef.current.state.sorting.sortModel?.[0] ?? { field: 'id', sort: 'desc' }
        const filtering = gridRef.current.state.filter.filterModel

        const page = gridRef.current.state.pagination.paginationModel?.page
        const rowsPerPage = gridRef.current.state.pagination.paginationModel?.pageSize
        setPaginationModel({ page: page, pageSize: rowsPerPage })

        if (reason !== 'restoreState') {
          const storageKey = process === PROJECT.PF_KAP ? STORAGE_KEY._PF_KAP_STORAGE : STORAGE_KEY._PF_PGV_STORAGE
          StorageUtils.store(storageKey, { sort, filtering, rowsPerPage })
        }

        await fetchRows(rowsPerPage, filtering.items, page, sort)
      }
    },
    [fetchRows, gridRef, process],
  )

  useEffect(() => {
    const initData = async () => {
      const { document, variables } = combinedQuery('applicationTypesAndFundingRoundsAndGfchResponsibleUsers')
        .add(getApplicationTypesQuery, { userProcesses: [process] })
        .add(getFundingRoundsByProcess, { process })
        .add(fetchGfchResponsibleUsersForProcessQuery, { dossierType: process })

      const { data } = await urqlClient
        .query<{
          application_type: Query_Root['application_type']
          funding_round: Query_Root['funding_round']
          project_base: Query_Root['project_base']
        }>(document, variables)
        .toPromise()

      if (data && data?.application_type && data?.funding_round) {
        setApplicationTypeOptions(
          data?.application_type.map((type) => ({
            label: getMessage(type.key),
            value: type.code,
          })),
        )
        setFundingRoundOptions(
          data?.funding_round.map((round) => ({
            label: `${round.name}`,
            value: round.id,
          })),
        )
        if (data?.project_base.length > 0) {
          setGfchResponsibleOptions(
            data?.project_base.map((project) => ({
              label: `${project.gfch_responsible?.first_name} ${project.gfch_responsible?.last_name}`,
              value: project.gfch_responsible_id as number,
            })),
          )
        }
      } else {
        notificationService.operationFailed()
      }
    }

    initData()
  }, [urqlClient, getMessage, notificationService, process])

  const applyFilters = useCallback(
    async (savedParams: any) => {
      // Checks if there is a GFCH responsible filtering value stored as a string due to previous implementation
      // Removes that parameter and prevents type error
      const gfchResponsibleFilterAsString = savedParams.filtering.items.find(
        (item: GridFilterItem) => item.field === 'gfch_responsible' && typeof item.value === 'string',
      )
      if (gfchResponsibleFilterAsString) {
        savedParams.filtering.items.splice({ field: 'gfch_responsible' })
      }

      const sort = savedParams?.sort
      const filtering = savedParams?.filtering
      const rowsPerPage = savedParams?.rowsPerPage ?? PAGE_SIZE

      await fetchRows(rowsPerPage, filtering.items, 0, sort)

      gridRef.current.setFilterModel(filtering, 'restoreState')
      gridRef.current.setSortModel([sort])
      gridRef.current.setPageSize(rowsPerPage)
    },
    [fetchRows, gridRef],
  )

  useEffect(() => {
    const storageKey = process === PROJECT.PF_KAP ? STORAGE_KEY._PF_KAP_STORAGE : STORAGE_KEY._PF_PGV_STORAGE
    const savedParams = StorageUtils.get(storageKey)
    if (savedParams) {
      applyFilters(savedParams)
      return
    }
    fetchRows()
  }, [fetchRows, process, applyFilters])

  return (
    <>
      <DataGridProStyled
        initialState={{
          pagination: {
            paginationModel: paginationModel,
          },
        }}
        autoHeight
        rows={rows}
        localeText={gridTranslations}
        columns={columns}
        disableRowSelectionOnClick={true}
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        apiRef={gridRef}
        rowCount={rowsCount}
        onPaginationModelChange={() => onGridChange()}
        onSortModelChange={(_, { reason }) => onGridChange(reason)}
        onFilterModelChange={(_, { reason }) => onGridChange(reason)}
        onRowClick={(params) => handleRowClick(params)}
        pagination
        pageSizeOptions={[10, 25, 50]}
        loading={loading}
        disableColumnPinning
        components={{
          Toolbar: () => ProjectToolbar,
        }}
      />
    </>
  )
}
